var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { id: "faq-category-page" } },
    [
      _c("div", { staticClass: "category-page-nav" }, [
        _c(
          "a",
          {
            attrs: { "aria-label": "Categories Link" },
            on: {
              click: function ($event) {
                _vm.logFaqPageNavigation({
                  type: "link",
                  action: "visit",
                  timestamp: Date.now(),
                })
              },
            },
          },
          [_vm._v("\n      Categories\n    ")]
        ),
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "16",
              height: "16",
              viewBox: "0 0 16 16",
              fill: "none",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M6 4L10 8L6 12",
                stroke: "black",
                "stroke-width": "1.33333",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
          ]
        ),
        _c("p", [_vm._v("\n      " + _vm._s(_vm.categoryName) + "\n    ")]),
      ]),
      _c("div", { staticClass: "faq-category-header" }, [
        _c("h1", [_vm._v(_vm._s(_vm.categoryName))]),
        _c("hr"),
      ]),
      !_vm.loaded ? _c("ct-centered-spinner") : _vm._e(),
      _vm.loaded
        ? _c("associated-pages", {
            staticClass: "mb-5",
            attrs: { "category-id": _vm.categoryId, "max-pages": 999 },
          })
        : _vm._e(),
      _c("div", { staticClass: "w-100 d-flex flex-row-reverse" }, [
        _vm.pageRoute
          ? _c(
              "a",
              {
                staticClass: "category-page-link",
                attrs: {
                  "aria-label": "Explore the " + _vm.categoryName + " Link",
                },
                on: {
                  click: function ($event) {
                    return _vm.logFaqCategoryNavigation(
                      _vm.categoryId,
                      {
                        action: "nav-to-page",
                        type: "link",
                        categoryName: _vm.categoryName,
                      },
                      _vm.pageRoute
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n      Explore the " + _vm._s(_vm.categoryName) + "\n      "
                ),
                _c("arrow-right", { staticClass: "arrow-icon" }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }