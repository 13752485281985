<template>
  <b-container id="faq-category-page">
    <div class="category-page-nav">
      <a aria-label="Categories Link"
        @click="logFaqPageNavigation({ type: 'link', action: 'visit', timestamp: Date.now() })">
        Categories
      </a>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M6 4L10 8L6 12" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <p>
        {{ categoryName }}
      </p>
    </div>
    <div class="faq-category-header">
      <h1>{{ categoryName }}</h1>
      <hr>
    </div>
    <ct-centered-spinner v-if="!loaded" />
    <associated-pages
      v-if="loaded"
      :category-id="categoryId"
      :max-pages="999"
      class="mb-5"
    />
    <div class="w-100 d-flex flex-row-reverse">
      <a v-if="pageRoute"
         :aria-label="'Explore the ' + categoryName + ' Link'"
         @click="logFaqCategoryNavigation(categoryId, { action: 'nav-to-page', type: 'link', categoryName: categoryName }, pageRoute)"
         class="category-page-link"
      >
        Explore the {{ categoryName }}
        <arrow-right class="arrow-icon" />
      </a>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AssociatedPages from '@/components/Faq/AssociatedPages.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { faqMixin } from '@/mixins/faqMixin'

export default {
  name: 'FaqCategory',
  components: {
    ArrowRight: () => import('@images/ui/arrow-right-2FB2AE.svg'),
    AssociatedPages,
    CtCenteredSpinner,
  },
  mixins: [faqMixin],
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('faq', ['faqInformation']),
    categoryId() {
      return this.$route.params.categoryId
    },
    categoryName() {
      return this.faqInformation?.find(cat => cat.id === this.categoryId)?.name
    },
    pageRoute() {
      return this.categoryRoutes.get(this.categoryName)
    },
  },
  async mounted() {
    if(!this.categoryName) {
      await this.fetchFaqInformation()
    }
    this.loaded = true
  },
  methods: {
    ...mapActions('faq', ['fetchFaqInformation']),
  },
}
</script>

<style lang="scss" scoped>
#faq-category-page {
  .category-page-link {
    color: #2FB2AE !important;
  }
  .category-page-link:hover {
    cursor: pointer;
  }
  .category-page-nav {
    display: flex;
    align-items: center;
    margin-top: 77px;
    a {
      font-weight: 600;
      color: #2FB2AE;
    }
    a:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    p {
      font-weight: 700;
      color: #404040;
      margin: 0;
    }
    svg {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
  .faq-category-header > hr {
    width: 100%;
    background: #707070;
    margin-bottom: 19px;
  }
}
::v-deep #associated-pages-component {
  .associated-page-container {
    margin: 0;
  }
  .associated-page-text {
    margin: 0;
  }
  hr {
    width: 100%;
  }
}
</style>
